<template>
  <v-app>
    <v-app-bar app color="white" height="100" elevation="2" absolute>
      <!-- Логотип Viortis -->
      <v-img
        src="./assets/images/logo.png"
        contain
        max-height="80"
        max-width="20%"
      />
      <!-- //Логотип Viortis -->
      <!-- Логотип SK -->
      <v-img
        src="./assets/images/sk-logo.png"
        contain
        max-height="80"
        max-width="15%"
        v-if="token != null"
      />
      <!-- //Логотип SK -->
      <v-spacer />
      <!-- Текущий пользователь -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn large text v-if="token != null" v-bind="attrs" v-on="on">
            <v-icon left large color="aquamarine"
              >mdi-account-circle-outline</v-icon
            >
            <span
              class="navyblue--text mx-2 font-weight-bold text-caption text-capitalize"
              >{{ token.userName }}</span
            >
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group>
            <v-list-item @click="logOut()">
              <v-list-item-icon>
                <v-icon color="aquamarine">mdi-account-remove-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Выход</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <!-- //Текущий пользователь -->
      <template v-slot:extension>
        <v-toolbar color="aquamarine" height="48" elevation="0" />
      </template>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/Common/Snackbar.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  computed: {
    ...mapGetters({
      getToken: "authorization/GET_TOKEN",
    }),
    token: {
      get: function () {
        return this.getToken;
      },
    },
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions({
      generateSnackbar: "common/GENERATE_SNACKBAR",
      generateLoader: "common/GENERATE_LOADER",
      updateToken: "authorization/UPDATE_TOKEN_FROM_API",
    }),
    logOut() {
      localStorage.removeItem("identity");
      var data = this.$router.resolve({
        path: "/account/login",
      });
      document.location.href = data.href;
    },
  },
  created() {
    this.generateSnackbar({
      show: false,
      timeout: 3000,
      color: "aquamarine",
      text: "",
    });

    this.generateLoader({
      data: false,
    });

    Object.keys(window).forEach((key) => {
      if (/^onclick/.test(key)) {
        window.addEventListener(key.slice(2), () => {
          if (this.token != null) {
            this.updateToken();
          }
        });
      }
    });

    setInterval(() => {
      this.updateToken();
    }, 1000 * 60 * 20);
  },
};
</script>
<style>
.v-toolbar__extension {
  padding: 0;
}
</style>