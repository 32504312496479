<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-text-field
        label="Поиск"
        v-model="query"
        color="aquamarine"
        hide-details
        dense
        outlined
        append-icon="mdi-magnify"
        @input="query = $event !== '' ? $event : null"
        clearable
        @click:append="search()"
        @keyup.enter="search()"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "SearchComponent",
  computed: {
    ...mapGetters({
      getQuery: "quantityReport/GET_QUERY",
      getReports: "quantityReport/GET_REPORTS",
      getPagingLength: "quantityReport/GET_PAGING_LENGTH",
      getSort: "quantityReport/GET_SORT",
    }),
    query: {
      get: function () {
        return this.getQuery;
      },
      set: function (newVal) {
        this.setQuery(newVal);
      },
    },
    reports: {
      get: function () {
        let array = this.getReports;
        if (array != null) {
          array = array.filter((x) => x.version >= 1);
        }

        return array;
      },
    },
    pagingLength: {
      get: function () {
        return this.getPagingLength;
      },
    },
    sort: {
      get: function () {
        return this.getSort;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getDataFromApi: "quantityReport/GET_DATA_FROM_API",
    }),
    ...mapMutations({
      setQuery: "quantityReport/SET_QUERY",
      setLoader: "common/SET_LOADER",
    }),
    async search() {
      this.setLoader({
        data: true,
      });

      await this.getDataFromApi({
        page: 1,
        pageSize: this.pagingLength,
        reports: this.reports.map((x) => ({
          name: x.name,
          date: x.date,
          version: x.version,
          reportType: x.type.reportType,
          hubLots: x.hubs.map((z) => z.lot),
        })),
        search: this.query,
        sort: this.sort,
      });

      this.setLoader({
        data: false,
      });
    },
  },
};
</script>