import quantityReport from '../api/index'
import { saveAs } from "file-saver";

const GetOptions = () => {
    return new Promise((resolve) => {
        resolve(quantityReport.quantityReport.GetOptions())
    })
}

const GetVersion = (query) => {
    return new Promise((resolve) => {
        resolve(quantityReport.quantityReport.GetVersion(query))
    })
}

const GetData = (query) => {
    return new Promise((resolve) => {
        resolve(quantityReport.quantityReport.GetData(query))
    })
}

const Export = (query) => {
    return new Promise((resolve) => {
        resolve(quantityReport.quantityReport.Export(query))
    })
}

export default {
    namespaced: true,
    state: {
        options: null,
        version: null,
        data: null,
        pagingLength: null,
        query: null,
        reports: null,
        headers: null,
        export: null,
        sort: null
    },
    getters: {
        GET_OPTIONS(state) {
            return state.options;
        },
        GET_VERSION(state) {
            return state.version;
        },
        GET_DATA(state) {
            return state.data;
        },
        GET_PAGING_LENGTH(state) {
            return state.pagingLength;
        },
        GET_QUERY(state) {
            return state.query;
        },
        GET_REPORTS(state) {
            return state.reports;
        },
        GET_HEADERS(state) {
            return state.headers;
        },
        GET_EXPORT(state) {
            return state.export;
        },
        GET_SORT(state) {
            return state.sort;
        },
    },
    mutations: {
        SET_OPTIONS(state, payload) {
            state.options = payload;
        },
        SET_VERSION(state, payload) {
            state.version = payload;
        },
        SET_DATA(state, payload) {
            state.data = payload;
        },
        SET_PAGING_LENGTH(state, payload) {
            state.pagingLength = payload;
        },
        SET_QUERY(state, payload) {
            state.query = payload;
        },
        SET_REPORTS(state, payload) {
            state.reports = payload;
        },
        SET_HEADERS(state, payload) {
            state.headers = payload;
        },
        SET_EXPORT(state, payload) {
            state.export = payload;
        },
        SET_SORT(state, payload) {
            state.sort = payload;
        },
    },
    actions: {
        COMMIT_PAGING_LENGTH({ commit }, payload) {
            commit('SET_PAGING_LENGTH', payload);
        },
        COMMIT_QUERY({ commit }, payload) {
            commit('SET_QUERY', payload);
        },
        COMMIT_REPORTS({ commit }, payload) {
            commit('SET_REPORTS', payload);
        },
        COMMIT_HEADERS({ commit }, payload) {
            commit('SET_HEADERS', payload);
        },
        COMMIT_SORT({ commit }, payload) {
            commit('SET_SORT', payload);
        },
        async GET_OPTIONS_FROM_API({ commit }) {
            await GetOptions().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_OPTIONS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_OPTIONS', null);
                })
        },
        async GET_VERSION_FROM_API({ commit }, payload) {
            await GetVersion(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_VERSION', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_VERSION', null);
                })
        },
        async GET_DATA_FROM_API({ commit }, payload) {
            await GetData(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_DATA', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_DATA', null);
                })
        },
        async EXPORT_FROM_API({ commit }, payload) {
            await Export(payload).then(
                ({
                    data, status
                }) => {
                    saveAs(data, `QuantityReport.xlsx`);
                    if (status === 200) {
                        commit('SET_EXPORT', { status: true });
                    }
                }
            )
                .catch(() => {
                    commit('SET_EXPORT', null);
                })
        },
    }
}