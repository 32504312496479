import Vue from 'vue'
import Vuex from 'vuex'
import authorization from './authorization'
import quantityReport from './quantityReport'
import common from './common'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        authorization,
        quantityReport,
        common
    }
})
