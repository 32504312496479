<template>
  <v-container>
    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-row class="d-flex align-center justify-end">
          <v-col cols="12" md="4" lg="4" xl="4">
            <PagingComponent />
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4">
            <SearchComponent />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <OptionsComponent />
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <UnloadComponent />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DataComponent />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import OptionsComponent from "../../components/QuanityReport/OptionsComponent.vue";
import DataComponent from "../../components/QuanityReport/DataComponent.vue";
import UnloadComponent from "../../components/QuanityReport/UnloadComponent.vue";
import PagingComponent from "../../components/QuanityReport/PagingComponent.vue";
import SearchComponent from "../../components/QuanityReport/SearchComponent.vue";
import { mapActions } from "vuex";
export default {
  name: "QuantityReportView",
  components: {
    OptionsComponent,
    DataComponent,
    UnloadComponent,
    PagingComponent,
    SearchComponent,
  },
  methods: {
    ...mapActions({
      commitPagingLength: "quantityReport/COMMIT_PAGING_LENGTH",
      commitQuery: "quantityReport/COMMIT_QUERY",
    }),
  },
  created() {
    this.commitPagingLength(10);
    this.commitQuery(null);
  },
};
</script>