<template>
  <v-row no-gutters>
    <v-col cols="12" class="text-right">
      <v-btn
        class="navyblue white--text text-capitalize"
        elevation="2"
        small
        @click="generate()"
        :loading="generateBtnLoader"
        :disabled="
          reports == null ||
          reports.length == 0 ||
          generateBtnLoader ||
          exportBtnLoader
        "
        >Показать</v-btn
      >
      <v-btn
        class="ml-6 aquamarine white--text text-capitalize"
        elevation="2"
        small
        @click="exportM()"
        :loading="exportBtnLoader"
        :disabled="
          reports == null ||
          reports.length == 0 ||
          generateBtnLoader ||
          exportBtnLoader
        "
        >Экспортировать</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "UnloadComponent",
  computed: {
    ...mapGetters({
      getReports: "quantityReport/GET_REPORTS",
      getPagingLength: "quantityReport/GET_PAGING_LENGTH",
      getExport: "quantityReport/GET_EXPORT",
      getQuery: "quantityReport/GET_QUERY",
      getSort: "quantityReport/GET_SORT",
    }),
    reports: {
      get: function () {
        let array = this.getReports;
        if (array != null) {
          array = array.filter((x) => x.version >= 1);
        }

        return array;
      },
    },
    pagingLength: {
      get: function () {
        return this.getPagingLength;
      },
    },
    export: {
      get: function () {
        return this.getExport;
      },
    },
    query: {
      get: function () {
        return this.getQuery;
      },
    },
    sort: {
      get: function () {
        return this.getSort;
      },
    },
  },
  data: () => ({
    generateBtnLoader: false,
    exportBtnLoader: false,
  }),
  methods: {
    ...mapActions({
      getDataFromApi: "quantityReport/GET_DATA_FROM_API",
      exportFromApi: "quantityReport/EXPORT_FROM_API",
    }),
    ...mapMutations({
      setLoader: "common/SET_LOADER",
    }),
    async generate() {
      this.generateBtnLoader = true;
      this.setLoader({
        data: true,
      });

      await this.getDataFromApi({
        page: 1,
        pageSize: this.pagingLength,
        reports: this.reports.map((x) => ({
          name: x.name,
          date: x.date,
          version: x.version,
          reportType: x.type.reportType,
          hubLots: x.hubs.map((z) => z.lot),
        })),
        search: this.query,
        sort: this.sort,
      });

      this.generateBtnLoader = false;
      this.setLoader({
        data: false,
      });
    },

    async exportM() {
      this.exportBtnLoader = true;
      this.setLoader({
        data: true,
      });

      await this.exportFromApi({
        reports: this.reports.map((x) => ({
          name: x.name,
          date: x.date,
          version: x.version,
          reportType: x.type.reportType,
          hubLots: x.hubs.map((z) => z.lot),
        })),
      });

      this.exportBtnLoader = false;
      this.setLoader({
        data: false,
      });
    },
  },
};
</script>