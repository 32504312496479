export default {
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            timeout: 3000,
            color: 'aquamarine',
            text: ''
        },
        loader: {
            data: false,
        }
    },
    getters: {
        GET_SNACKBAR(state) {
            return state.snackbar;
        },
        GET_LOADER(state) {
            return state.loader;
        }
    },
    mutations: {
        SET_SNACKBAR(state, payload) {
            state.snackbar = payload;
        },
        SET_LOADER(state, payload) {
            state.loader = payload;
        },
    },
    actions: {
        GENERATE_SNACKBAR({ commit }, payload) {
            commit('SET_SNACKBAR', payload);
        },
        GENERATE_LOADER({ commit }, payload) {
            commit('SET_LOADER', payload);
        },
    }
}