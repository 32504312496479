import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                aquamarine: '#01B2AA',
                navyblue: '#21304F'

            },
            dark: {
                aquamarine: '#01B2AA',
                navyblue: '#21304F'
            },
        },

    }
});
