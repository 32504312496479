export default function (instance) {
    return {
        GetOptions() {
            return instance.post('/QuantityReport/GetOptions');
        },
        GetVersion(payload) {
            return instance.post('/QuantityReport/GetVersion', payload);
        },
        GetData(payload) {
            return instance.post('/QuantityReport/GetData', payload);
        },
        Export(payload) {
            return instance.post('/QuantityReport/Export', payload, { responseType: 'blob' });
        }
    }
}
