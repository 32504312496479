var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Столбы","items":_vm.commonHeaders,"color":"aquamarine","item-text":"text","item-value":"text","return-object":"","dense":"","hide-details":"","multiple":"","small-chips":"","outlined":"","disabled":_vm.reports != null ? _vm.reports.length > 0 : true},model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1)],1),(_vm.loader.data)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"navyblue"}}):_vm._e(),_c('v-simple-table',{staticClass:"elevation-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,class:header.class,staticStyle:{"border-color":"#eeeeee !important"},attrs:{"rowspan":header.children ? 1 : 2,"colspan":header.children ? header.children.length : 1}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_vm._v(" "+_vm._s(header.text)+" "),(
                    (hover ||
                      (_vm.sort != null ? _vm.sort.column == header.value : false)) &&
                    !header.children
                  )?_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.selectSortColumn(header.value, false)}}},[(_vm.sort != null ? _vm.sort.descending == true : true)?_c('v-icon',{attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-sort-ascending")]):(
                      _vm.sort != null ? _vm.sort.descending == false : false
                    )?_c('v-icon',{attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-sort-descending")]):_vm._e()],1):_vm._e()],1)]}}],null,true)})],1)}),0),_c('tr',_vm._l((_vm.getSubHeader(_vm.headers)),function(childHeader,childIndex){return _c('th',{key:childIndex,class:childHeader.class,staticStyle:{"border-color":"#eeeeee !important","border-top":"thin solid #eeeeee !important"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_vm._v(" "+_vm._s(childHeader.text)+" "),(
                    hover ||
                    (_vm.sort != null
                      ? _vm.sort.column == childHeader.text &&
                        _vm.sort.report == childHeader.value.split('.')[1]
                      : false)
                  )?_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.selectSortColumn(childHeader.value, true)}}},[(_vm.sort != null ? _vm.sort.descending == true : true)?_c('v-icon',{attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-sort-ascending")]):(
                      _vm.sort != null ? _vm.sort.descending == false : false
                    )?_c('v-icon',{attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-sort-descending")]):_vm._e()],1):_vm._e()],1)]}}],null,true)})],1)}),0)]),_c('tbody',[_vm._l((_vm.data != null ? _vm.data.items : []),function(item,index){return _c('tr',{key:index,staticClass:"text-center"},[_vm._l((_vm.headers),function(header,index){return [(!header.children)?_c('td',{key:'h_' + index,staticClass:"v-data-table__divider"},[_vm._v(" "+_vm._s(_vm.getPropByString(item, header.value))+" ")]):_vm._e()]}),_vm._l((_vm.getSubHeader(_vm.headers)),function(header,index){return _c('td',{key:'c_' + index,staticClass:"v-data-table__divider"},[_vm._v(" "+_vm._s(_vm.getPropByString(item, header.value))+" ")])})],2)}),(_vm.data == null)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.headers.length}},[_c('span',{staticClass:"text-caption"},[_vm._v("Нет данных для отображения...")])])]):_vm._e()],2)]},proxy:true}])}),_c('v-card',{staticClass:"my-4 mx-auto",attrs:{"max-width":"20%","elevation":"0"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"color":"aquamarine"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }