<template>
  <v-card elevation="2">
    <v-card-title class="px-0 pt-0">
      <v-toolbar color="aquamarine" elevation="2"> </v-toolbar>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-row class="justify-center align-center">
          <v-col cols="10" class="justify-center text-center">
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
            <v-text-field
              prepend-icon="mdi-account"
              filled
              dense
              v-model="userName"
              label="Имя пользователя"
              color="aquamarine"
              type="text"
              :rules="[
                userNameRequiredRule(userName),
                userNameMinLengthRule(userName),
                userNameMaxLengthRule(userName),
              ]"
              :error-messages="
                errorMessages
                  .filter((x) =>
                    ['userNameRequired', 'userNameMin', 'userNameMax'].includes(
                      x.ruleName
                    )
                  )
                  .map((x) => x.text)
                  .join()
              "
            >
            </v-text-field>
            <v-text-field
              prepend-icon="mdi-lock"
              filled
              dense
              v-model="password"
              label="Пароль"
              color="aquamarine"
              :append-icon="
                passwordShow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              :type="passwordShow ? 'text' : 'password'"
              @click:append="passwordShow = !passwordShow"
              :rules="[
                passwordRequiredRule(password),
                passwordMinLengthRule(password),
                passwordMaxLengthRule(password),
              ]"
              :error-messages="
                errorMessages
                  .filter((x) =>
                    ['passwordRequired', 'passwordMin', 'passwordMax'].includes(
                      x.ruleName
                    )
                  )
                  .map((x) => x.text)
                  .join()
              "
            >
            </v-text-field>
            <v-btn
              class="aquamarine white--text"
              @click="enter()"
              elevation="2"
              :loading="loading"
              :disabled="loading"
            >
              Вход
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "AuthenticationFormComponent",
  components: {},
  computed: {
    ...mapGetters({
      getToken: "authorization/GET_TOKEN",
    }),
    token: {
      get: function () {
        return this.getToken;
      },
    },
  },
  data: () => ({
    userName: null,
    password: null,
    passwordShow: false,
    loading: false,
    modelState: [
      {
        ruleName: "passwordRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMin",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMax",
        status: false,
        text: null,
      },
      {
        ruleName: "userNameRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "userNameMin",
        status: false,
        text: null,
      },
      {
        ruleName: "userNameMax",
        status: false,
        text: null,
      },
    ],
    errorMessages: [],
  }),
  methods: {
    ...mapActions({
      getTokenFromApi: "authorization/GET_TOKEN_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async enter() {
      var validateStatus = this.modelState.every((x) => x.status);
      if (validateStatus) {
        this.loading = true;
        await this.getTokenFromApi({
          UserName: this.userName,
          Password: this.password,
        });

        if (this.token == null) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибки при входе!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Вход успешно выполнен!",
          });
          var data = this.$router.resolve({
            path: "/quantityReport",
          });
          document.location.href = data.href;
        }
        this.loading = false;
      } else {
        this.errorMessages = this.modelState
          .filter((x) => !x.status)
          .map((x) => ({
            ruleName: x.ruleName,
            text: x.text,
          }));
      }
    },
    passwordRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Введите пароль!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMin"
      );
      if (v != null && v.length < 6) {
        currentRule.status = false;
        currentRule.text = "Минимальная длина пароля 6 символов!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMax"
      );
      if (v != null && v.length > 255) {
        currentRule.status = false;
        currentRule.text = "Максимальная длина пароля 255 символов!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    userNameRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "userNameRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Введите имя пользователя!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("userNameRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    userNameMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "userNameMin"
      );
      if (v != null && v.length < 3) {
        currentRule.status = false;
        currentRule.text = "Минимальная длина имени пользователя 6 символов!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("userNameMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    userNameMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "userNameMax"
      );
      if (v != null && v.length > 255) {
        currentRule.status = false;
        currentRule.text =
          "Максимальная длина имени пользователя 255 символов!";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("userNameMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
  },
};
</script>