import Vue from 'vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import Login from "../views/account/Login"
import QuantityReport from "../views/quantityReport/QuantityReport"

var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/quantityReport'
        },
        {
            path: '/account/login',
            component: Login
        },
        {
            path: '/quantityReport',
            component: QuantityReport
        },
    ]
})

export default router