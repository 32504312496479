<template>
  <div v-if="reports != null">
    <v-data-table
      :headers="headers"
      :items="reports"
      class="elevation-2"
      hide-default-footer
      :loading="loading"
    >
      <template slot="progress">
        <v-progress-linear color="navyblue" indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-text-field
          label="Наименование отчета"
          v-model="item.name"
          color="aquamarine"
          counter="32"
          @input="item.name = $event !== '' ? $event : null"
          @blur="handleName(item)"
        >
        </v-text-field>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <v-menu
          v-model="item.menu"
          transition="scale-transition"
          offset-y
          min-width="auto"
          @input="handleDate(item)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.formattedDate"
              color="aquamarine"
              readonly
              v-bind="attrs"
              v-on="on"
              @input="item.date = $event !== '' ? $event : null"
              append-icon="mdi-calendar-range"
              v-if="options != null"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="item.date"
            no-title
            scrollable
            color="aquamarine"
            locale="ru"
            first-day-of-week="1"
            :min="options.minDate"
            :max="options.maxDate"
          >
          </v-date-picker>
        </v-menu>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-select
          label="Тип отчета"
          v-model="item.type"
          color="aquamarine"
          :items="options.availableReports"
          item-text="name"
          item-value="reportType"
          return-object
          @blur="handleReportTypeMenu(item)"
        >
        </v-select>
      </template>
      <template v-slot:[`item.hubs`]="{ item }">
        <v-select
          label="Хабы"
          v-model="item.hubs"
          color="aquamarine"
          :items="options.availableHubs"
          item-text="lot"
          item-value="lot"
          multiple
          return-object
          @change="changeHubs(item.hubs)"
          @blur="handleHubsMenu(item)"
        >
        </v-select>
      </template>
      <template v-slot:[`item.version`]="{ item, index }">
        <v-btn
          v-if="item.version == null"
          text
          fab
          @click="checkVersion(item.num)"
          small
          color="navyblue"
        >
          <v-icon>mdi-check-circle-outline</v-icon>
        </v-btn>
        <v-btn v-else-if="item.version == 0" text fab small color="yellow">
          <v-icon>mdi-alert-circle-outline</v-icon>
        </v-btn>
        <v-btn
          v-else
          text
          fab
          small
          color="green"
          @click="checkVersion(item.num)"
        >
          <v-icon>mdi-check-circle-outline</v-icon>
        </v-btn>

        <v-btn
          text
          fab
          small
          color="red"
          @click="deleteReport(index)"
          v-if="reports.length > 1"
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
      <template slot="body.append">
        <tr v-if="reports.length < 10" @click="addRow()">
          <td :colspan="headers.length" class="text-center">
            <v-btn text fab small color="navyblue">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
  <div v-else>
    <v-skeleton-loader loading type="table-heading, list-item-two-line" />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "OptionsComponent",
  computed: {
    ...mapGetters({
      getOptions: "quantityReport/GET_OPTIONS",
      getVersion: "quantityReport/GET_VERSION",
      getReports: "quantityReport/GET_REPORTS",
      getHeaders: "quantityReport/GET_HEADERS",
    }),
    options: {
      get: function () {
        return this.getOptions;
      },
    },
    version: {
      get: function () {
        return this.getVersion;
      },
    },
    reports: {
      get: function () {
        return this.getReports;
      },
      set: function (newVal) {
        this.setReports(newVal);
      },
    },
    dataHeaders: {
      get: function () {
        return this.getHeaders;
      },
      set: function (newVal) {
        this.setHeaders(newVal);
      },
    },
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "#",
        value: "num",
        align: "center",
        sortable: false,
        class: "aquamarine white--text",
        width: "5%",
      },
      {
        text: "Наименование отчета",
        value: "name",
        align: "center",
        sortable: false,
        class: "aquamarine white--text",
        width: "30%",
      },
      {
        text: "Дата",
        value: "date",
        align: "center",
        sortable: false,
        class: "aquamarine white--text",
        width: "15%",
      },
      {
        text: "Тип отчета",
        value: "type",
        align: "center",
        sortable: false,
        class: "aquamarine white--text",
        width: "20%",
      },
      {
        text: "Хабы",
        value: "hubs",
        align: "center",
        sortable: false,
        class: "aquamarine white--text",
        width: "20%",
      },
      {
        text: "",
        value: "version",
        align: "center",
        sortable: false,
        class: "aquamarine white--text",
        width: "10%",
      },
    ],
    reportHeaders: [],
  }),
  methods: {
    ...mapActions({
      getOptionsFromApi: "quantityReport/GET_OPTIONS_FROM_API",
      getVersionFromApi: "quantityReport/GET_VERSION_FROM_API",
      commitReports: "quantityReport/COMMIT_REPORTS",
    }),
    ...mapMutations({
      setReports: "quantityReport/SET_REPORTS",
      setHeaders: "quantityReport/SET_HEADERS",
    }),
    addRow() {
      let maxNum = Math.max(...this.reports.map((x) => x.num), 0);
      this.reports.push({
        num: ++maxNum,
        menu: false,
        name: null,
        date: null,
        type: null,
        hubs: null,
        version: null,
        typeMenu: false,
        hubsMenu: false,
        nameMenu: false,
        dateMenu: false,
        formattedDate: null,
      });
    },
    async checkVersion(num) {
      let currentItem = this.reports.find((x) => x.num == num);

      await this.getVersionFromApi({
        date: currentItem.date,
        reportType: currentItem.type.reportType,
      });

      if (this.version != null && this.version.reportExists) {
        currentItem.version = this.version.version;

        let reportsArr = this.reports;
        reportsArr = reportsArr
          .filter((x) => x.version >= 1)
          .flatMap((x) => ({
            name: x.name,
            lots: x.hubs.map((z) => z.lot),
          }));

        reportsArr.forEach(function (element) {
          if (element.lots.length > 1) {
            element.lots.push("total");
          }
        });

        let reportHeaders = reportsArr.map((x) => ({
          text: `${x.name}`,
          value: `reports.${x.name}`,
          children: x.lots.map((z) => ({
            text: z,
            class: "aquamarine white--text text-center v-data-table__divider",
            value: `reports.${x.name}.${z}`,
          })),
          align: "center",
          sortable: false,
          class: "aquamarine white--text text-center v-data-table__divider",
        }));

        this.reportHeaders = reportHeaders;
      } else {
        currentItem.version = 0;
      }
    },
    deleteReport(index) {
      let headerValue = `reports.${this.reports[index].name}`;

      if (headerValue != null) {
        this.reportHeaders = this.reportHeaders.filter(
          (x) => x.value != headerValue
        );
      }

      this.reports.splice(index, 1);
      this.updateRowNumbers();
    },
    changeHubs(hubs) {
      hubs.sort((a, b) => a.lot - b.lot);
    },
    updateRowNumbers() {
      let startRowNum = 0;
      this.reports.forEach(function (elem) {
        elem.num = ++startRowNum;
      });
    },
    handleReportTypeMenu(item) {
      item.version = null;
      item.typeMenu = item.type != null ? true : false;
    },
    handleHubsMenu(item) {
      item.version = null;
      item.hubsMenu = item.hubs != null ? true : false;
    },
    handleName(item) {
      item.version = null;
      item.nameMenu = item.name != null ? true : false;
    },
    handleDate(item) {
      item.version = null;
      item.dateMenu = item.date != null ? true : false;
    },
  },
  watch: {
    reportHeaders: function () {
      let commonHeaders = this.dataHeaders.filter((x) => x.common);
      this.dataHeaders = commonHeaders.concat(this.reportHeaders);
    },
    reports: {
      handler: function () {
        let self = this;
        this.reports.forEach(function (elem) {
          if (elem.date != null) {
            elem.formattedDate = new Date(elem.date).toLocaleDateString();
          }
          if (
            elem.typeMenu &&
            elem.hubsMenu &&
            elem.nameMenu &&
            elem.dateMenu &&
            (elem.version == null || elem.version == 0)
          ) {
            self.checkVersion(elem.num);
          }
        });
      },
      deep: true,
    },
  },
  async created() {
    this.loading = true;
    await this.getOptionsFromApi();
    this.commitReports([
      {
        num: 1,
        menu: false,
        name: null,
        date: null,
        type: null,
        hubs: null,
        version: null,
        typeMenu: false,
        hubsMenu: false,
        nameMenu: false,
        dateMenu: false,
        formattedDate: null,
      },
    ]);
    this.loading = false;
  },
};
</script>