import authorization from '../api/index'

const GetToken = (query) => {
    return new Promise((resolve) => {
        resolve(authorization.authorization.GetToken(query))
    })
}

const UpdateToken = () => {
    return new Promise((resolve) => {
        resolve(authorization.authorization.UpdateToken())
    })
}

export default {
    namespaced: true,
    state: {
        token: null,
    },
    getters: {
        GET_TOKEN(state) {
            if (localStorage.getItem('identity') != null) {
                state.token = JSON.parse(localStorage.getItem('identity'));
            }
            return state.token;
        },
    },
    mutations: {
        SET_TOKEN(state, payload) {
            if (payload != null)
                localStorage.setItem('identity', JSON.stringify(payload));

            state.token = payload;
        },
    },
    actions: {
        async GET_TOKEN_FROM_API({ commit }, payload) {
            await GetToken(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_TOKEN', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_TOKEN', null);
                })
        },

        async UPDATE_TOKEN_FROM_API({ commit }) {
            await UpdateToken().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_TOKEN', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_TOKEN', null);
                })
        },
    }
}