<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="12">
        <v-select
          label="Столбы"
          v-model="selectedColumns"
          :items="commonHeaders"
          color="aquamarine"
          item-text="text"
          item-value="text"
          return-object
          dense
          hide-details
          multiple
          small-chips
          outlined
          :disabled="reports != null ? reports.length > 0 : true"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loader.data"
      indeterminate
      color="navyblue"
    ></v-progress-linear>
    <v-simple-table class="elevation-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              :class="header.class"
              :rowspan="header.children ? 1 : 2"
              :colspan="header.children ? header.children.length : 1"
              style="border-color: #eeeeee !important"
            >
              <v-hover v-slot="{ hover }">
                <div>
                  {{ header.text }}
                  <v-btn
                    x-small
                    text
                    v-if="
                      (hover ||
                        (sort != null ? sort.column == header.value : false)) &&
                      !header.children
                    "
                    @click="selectSortColumn(header.value, false)"
                  >
                    <v-icon
                      x-small
                      color="white"
                      v-if="sort != null ? sort.descending == true : true"
                      >mdi-sort-ascending</v-icon
                    >
                    <v-icon
                      x-small
                      color="white"
                      v-else-if="
                        sort != null ? sort.descending == false : false
                      "
                      >mdi-sort-descending</v-icon
                    >
                  </v-btn>
                </div>
              </v-hover>
            </th>
          </tr>
          <tr>
            <th
              v-for="(childHeader, childIndex) in getSubHeader(headers)"
              :key="childIndex"
              :class="childHeader.class"
              style="
                border-color: #eeeeee !important;
                border-top: thin solid #eeeeee !important;
              "
            >
              <v-hover v-slot="{ hover }">
                <div>
                  {{ childHeader.text }}
                  <v-btn
                    x-small
                    text
                    v-if="
                      hover ||
                      (sort != null
                        ? sort.column == childHeader.text &&
                          sort.report == childHeader.value.split('.')[1]
                        : false)
                    "
                    @click="selectSortColumn(childHeader.value, true)"
                  >
                    <v-icon
                      x-small
                      color="white"
                      v-if="sort != null ? sort.descending == true : true"
                      >mdi-sort-ascending</v-icon
                    >
                    <v-icon
                      x-small
                      color="white"
                      v-else-if="
                        sort != null ? sort.descending == false : false
                      "
                      >mdi-sort-descending</v-icon
                    >
                  </v-btn>
                </div>
              </v-hover>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in data != null ? data.items : []"
            :key="index"
            class="text-center"
          >
            <template v-for="(header, index) in headers">
              <td
                :key="'h_' + index"
                v-if="!header.children"
                class="v-data-table__divider"
              >
                {{ getPropByString(item, header.value) }}
              </td>
            </template>

            <td
              v-for="(header, index) in getSubHeader(headers)"
              :key="'c_' + index"
              class="v-data-table__divider"
            >
              {{ getPropByString(item, header.value) }}
            </td>
          </tr>
          <tr v-if="data == null">
            <td :colspan="headers.length" class="text-center">
              <span class="text-caption">Нет данных для отображения...</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card max-width="20%" elevation="0" class="my-4 mx-auto">
      <v-pagination
        v-model="page"
        :length="totalPages"
        color="aquamarine"
      ></v-pagination>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "DataComponent",
  computed: {
    ...mapGetters({
      getData: "quantityReport/GET_DATA",
      getPagingLength: "quantityReport/GET_PAGING_LENGTH",
      getLoader: "common/GET_LOADER",
      getReports: "quantityReport/GET_REPORTS",
      getHeaders: "quantityReport/GET_HEADERS",
      getQuery: "quantityReport/GET_QUERY",
      getSort: "quantityReport/GET_SORT",
    }),
    data: {
      get: function () {
        return this.getData;
      },
    },
    pagingLength: {
      get: function () {
        return this.getPagingLength;
      },
    },
    totalPages: {
      get: function () {
        if (this.data == null) return 0;
        let totalPages = Math.ceil(this.data.totalItems / this.pagingLength);
        return totalPages;
      },
    },
    loader: {
      get: function () {
        return this.getLoader;
      },
      set: function (newVal) {
        this.setLoader(newVal);
      },
    },
    reports: {
      get: function () {
        let array = this.getReports;
        if (array != null) {
          array = array.filter((x) => x.version >= 1);
        }

        return array;
      },
    },
    headers: {
      get: function () {
        return this.getHeaders;
      },
      set: function (newVal) {
        this.setHeaders(newVal);
      },
    },
    query: {
      get: function () {
        return this.getQuery;
      },
    },
    sort: {
      get: function () {
        return this.getSort;
      },
      set: function (newVal) {
        this.setSort(newVal);
      },
    },
  },
  data: () => ({
    selectedColumns: [],
    page: 1,
    commonHeaders: [
      {
        text: "СКП",
        value: "skp",
        sortable: false,
        class: "aquamarine white--text text-center v-data-table__divider",
        common: true,
      },
      {
        text: "СПП",
        value: "spp",
        sortable: false,
        class: "aquamarine white--text text-center v-data-table__divider",
        common: true,
      },
      {
        text: "Температура",
        value: "temperatureConditions",
        sortable: false,
        class: "aquamarine white--text text-center v-data-table__divider",
        common: true,
      },
      {
        text: "План",
        value: "assistanceForm",
        sortable: false,
        class: "aquamarine white--text text-center v-data-table__divider",
        common: true,
      },
      {
        text: "Торговое наименование",
        value: "tradeName",
        sortable: false,
        class: "aquamarine white--text text-center v-data-table__divider",
        common: true,
      },
      {
        text: "Лекарственная форма",
        value: "dosageForm",
        sortable: false,
        class: "aquamarine white--text text-center v-data-table__divider",
        common: true,
      },
      {
        text: "Ед. измерения",
        value: "unit",
        sortable: false,
        class: "aquamarine white--text text-center v-data-table__divider",
        common: true,
      },
      {
        text: "Фасовка",
        value: "package",
        sortable: false,
        class: "aquamarine white--text text-center v-data-table__divider",
        common: true,
      },
    ],
  }),
  methods: {
    ...mapActions({
      getDataFromApi: "quantityReport/GET_DATA_FROM_API",
      commitHeaders: "quantityReport/COMMIT_HEADERS",
      commitSort: "quantityReport/COMMIT_SORT",
    }),
    ...mapMutations({
      setHeaders: "quantityReport/SET_HEADERS",
      setLoader: "common/SET_LOADER",
      setSort: "quantityReport/SET_SORT",
    }),
    async getItemsFromApi() {
      this.loader = {
        data: true,
      };
      await this.getDataFromApi({
        page: this.page,
        pageSize: this.pagingLength,
        reports: this.reports.map((x) => ({
          name: x.name,
          date: x.date,
          version: x.version,
          reportType: x.type.reportType,
          hubLots: x.hubs.map((z) => z.lot),
        })),
        search: this.query,
        sort: this.sort,
      });
      this.loader = {
        data: false,
      };
    },
    getPropByString(obj, propString) {
      if (!propString) return obj;

      var prop,
        props = propString.split(".");

      for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i];

        var candidate = obj[prop];
        if (candidate !== undefined) {
          obj = candidate;
        } else {
          break;
        }
      }
      return obj[props[i]];
    },
    getSubHeader(headers) {
      let result = [];
      headers
        .filter((i) => i.children)
        .forEach((v) => {
          result = result.concat(v.children);
        });
      return result;
    },
    async selectSortColumn(value, isChild) {
      let model = {
        report: null,
        column: value,
        descending:
          this.sort != null && this.sort.column == value
            ? !this.sort.descending
            : true,
      };

      if (isChild) {
        let splittedValue = value.split(".");
        model.report = splittedValue[1];
        model.column = splittedValue[2];
        model.descending =
          this.sort != null &&
          this.sort.column == splittedValue[2] &&
          this.sort.report == splittedValue[1]
            ? !this.sort.descending
            : true;
      }

      this.sort = model;

      await this.getItemsFromApi();
    },
  },
  watch: {
    page: function () {
      this.getItemsFromApi();
    },
    pagingLength: function () {
      this.page = 1;
      this.getItemsFromApi();
    },
    selectedColumns: function () {
      this.headers = this.selectedColumns;
    },
  },
  created() {
    this.commitHeaders(this.commonHeaders);
    this.commitSort(null);
    this.selectedColumns = this.headers;
  },
};
</script>