<template>
  <v-row no-gutters>
    <v-col cols="12" class="d-inline-flex justify-center align-center">
      <span class="navyblue--text text-caption font-weight-bold">Показать</span>
      <v-select
        class="mx-4"
        :items="items"
        v-model="pagingLength"
        color="aquamarine"
        dense
        hide-details
        outlined
      >
      </v-select>
      <span class="navyblue--text text-caption font-weight-bold">строк</span>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "PagingComponent",
  computed: {
    ...mapGetters({
      getPagingLength: "quantityReport/GET_PAGING_LENGTH",
    }),
    pagingLength: {
      get: function () {
        return this.getPagingLength;
      },
      set: function (newVal) {
        this.setPagingLength(newVal);
      },
    },
  },
  data: () => ({
    items: [10, 20, 50, 100],
  }),
  methods: {
    ...mapMutations({
      setPagingLength: "quantityReport/SET_PAGING_LENGTH",
    }),
  },
};
</script>