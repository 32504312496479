var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"navyblue white--text text-capitalize",attrs:{"elevation":"2","small":"","loading":_vm.generateBtnLoader,"disabled":_vm.reports == null ||
        _vm.reports.length == 0 ||
        _vm.generateBtnLoader ||
        _vm.exportBtnLoader},on:{"click":function($event){return _vm.generate()}}},[_vm._v("Показать")]),_c('v-btn',{staticClass:"ml-6 aquamarine white--text text-capitalize",attrs:{"elevation":"2","small":"","loading":_vm.exportBtnLoader,"disabled":_vm.reports == null ||
        _vm.reports.length == 0 ||
        _vm.generateBtnLoader ||
        _vm.exportBtnLoader},on:{"click":function($event){return _vm.exportM()}}},[_vm._v("Экспортировать")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }