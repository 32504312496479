export default function (instance) {
    return {
        GetToken(payload) {
            return instance.post('/Authorization/GetToken', payload);
        },
        UpdateToken() {
            return instance.post('/Authorization/UpdateToken');
        }
    }
}
